// @flow

import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import Layout from '../../../components/Layout/index'
import Header from '../../../modules/Header'
import Footer from '../../../modules/Footer'
import CaseStudy from '../../../components/CaseStudy'
import SEO from '../../../components/seo'
import messages from '../../../messages/pageTitles.lang'
import dimensionMessages from '../../../messages/dimensions.lang'
import metaDescription from '../../../messages/pageMetaDescriptions.lang'

import baseStyles from './plattenplaner.scss'
import baseCaseMessages from '../../../components/CaseStudy/CaseStudy.lang'
import caseMessages from '../../../components/CaseStudy/messages/Plattenplaner/Plattenplaner.lang'

/* eslint-disable max-len */
const heroBgDesktop = (
  <StaticImage src="../../../images/casestudy/herobgdesktop.jpg" alt="" objectFit="cover" className="image" />
)
const heroImgDesktop = (
  <StaticImage
    src="../../../images/casestudy/plattenplaner/plattenplaner-mainimage@2x.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const welcomeImg = (
  <StaticImage
    src="../../../images/casestudy/plattenplaner/plattenplaner-welcome@2x.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const infoImg = (
  <StaticImage
    src="../../../images/casestudy/plattenplaner/plattenplaner-target-group@2x.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const func1Img = (
  <StaticImage
    src="../../../images/casestudy/plattenplaner/plattenplaner-functions@2x.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const func2Img = (
  <StaticImage
    src="../../../images/casestudy/plattenplaner/plattenplaner-requirements@2x.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const brandingImg = (
  <StaticImage src="../../../images/casestudy/plattenplaner/logo.svg" alt="" className="image image-brand" />
)
const colorsImg = (
  <StaticImage src="../../../images/casestudy/plattenplaner/Colors.png" alt="" objectFit="contain" className="image" />
)
const recognitionImg = (
  <StaticImage
    src="../../../images/casestudy/plattenplaner/plattenplaner-branding-business-cards.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const techImg = (
  <StaticImage src="../../../images/casestudy/plattenplaner/tech.png" alt="" objectFit="contain" className="image" />
)

import welcomeLogoSrc from '../../../images/casestudy/plattenplaner/logo.svg'
const welcomeLogo = <img src={welcomeLogoSrc} alt="" className="image" />
/* eslint-enable max-len */

import { projectsList } from '../../../components/CaseStudy/helpers'

type Props = {
  ...StyleProps
}

const data = {
  // link: 'https://plattenplaner.de/',
  name: 'Plattenplaner',
  videoId: 'krvp8hNzc2U',
  videoTitle: caseMessages.videoTitle,
  heroTeaser: {
    bg: {
      imageDesktop: heroBgDesktop
    },
    img: heroImgDesktop,
    title: projectsList.plattenplaner.title,
    description: projectsList.plattenplaner.description
  },
  facts: [
    {
      title: baseCaseMessages.challenge,
      text: caseMessages.fact1Text,
      color: 'tertiary',
      icon: 'icon-eye'
    },
    {
      title: baseCaseMessages.solution,
      text: caseMessages.fact2Text,
      color: 'secondary',
      icon: 'icon-board'
    },
    {
      title: baseCaseMessages.approach,
      text: caseMessages.fact3Text,
      color: 'accent',
      icon: 'icon-devices'
    }
  ],
  welcome: {
    logo: welcomeLogo,
    text: caseMessages.welcomeText,
    img: welcomeImg
  },
  info: {
    img: infoImg,
    title: caseMessages.infoTitle,
    list: [caseMessages.infoItem1, caseMessages.infoItem2, caseMessages.infoItem3]
  },
  stats: [
    {
      message: caseMessages.stat1Message,
      value: caseMessages.stat1Value,
      color: 'primary',
      icon: 'icon-calendar-heart'
    },
    {
      number: 10,
      numberDimension: dimensionMessages.dimensionThousand,
      value: caseMessages.stat2Value,
      color: 'tertiary',
      icon: 'icon-user'
    },
    {
      number: 24,
      numberDimension: dimensionMessages.dimensionBillion,
      value: caseMessages.stat3Value,
      color: 'secondary',
      icon: 'icon-link'
    },
    {
      number: 100,
      numberDimension: dimensionMessages.dimensionMillion,
      value: caseMessages.stat4Value,
      color: 'accent',
      icon: 'icon-plane'
    }
  ],
  functionalitiesTitle: baseCaseMessages.functionalitiesTitle,
  functionalities: [
    {
      title: caseMessages.functionality1Title,
      list: [
        caseMessages.functionality1Item1,
        caseMessages.functionality1Item2,
        caseMessages.functionality1Item3,
        caseMessages.functionality1Item4
      ],
      img: func1Img
    },
    {
      title: caseMessages.functionality2Title,
      list: [caseMessages.functionality2Item1, caseMessages.functionality2Item2],
      img: func2Img
    }
  ],
  roadmap: [
    {
      title: baseCaseMessages.roadmapWorkshop,
      timeValue: '',
      timeMeasure: '',
      color: 'primary',
      icon: 'icon-flag'
    },
    {
      title: baseCaseMessages.roadmapConception,
      timeValue: '',
      timeMeasure: '',
      color: 'tertiary',
      icon: 'icon-quality'
    },
    {
      title: baseCaseMessages.roadmapDevelopment,
      timeValue: '',
      timeMeasure: '',
      color: 'secondary',
      icon: 'icon-memory'
    },
    {
      title: baseCaseMessages.roadmapRelease,
      timeLabel: '',
      timeValue: '',
      timeMeasure: '',
      color: 'accent',
      icon: 'icon-heart-filled'
    }
  ],
  brand: {
    title: baseCaseMessages.brandsTitle,
    logo: {
      title: baseCaseMessages.logoTitle,
      list: [caseMessages.brandLogoItem1, caseMessages.brandLogoItem2, caseMessages.brandLogoItem3],
      img: brandingImg
    },
    colors: {
      title: baseCaseMessages.colorsTitle,
      list: [caseMessages.brandColorsItem1, caseMessages.brandColorsItem2, caseMessages.brandColorsItem3],
      img: colorsImg
    },
    recognition: {
      title: baseCaseMessages.recognitionTitle,
      text: caseMessages.brandRecognitionText,
      img: recognitionImg
    },
    tech: {
      title: baseCaseMessages.techTitle,
      list: [
        baseCaseMessages.techPerfomance,
        baseCaseMessages.techScalable,
        baseCaseMessages.techFlexible,
        baseCaseMessages.techArt
      ],
      img: techImg
    },
    design: {
      title: baseCaseMessages.designTitle,
      text: baseCaseMessages.designText,
      list: [baseCaseMessages.designItemInnovative, baseCaseMessages.designItemDevices],
      img: projectsList.plattenplaner.overviewImage
    }
  } // ,
  // check: {
  //   img: projectsList.plattenplaner.marketingImage,
  //   title: baseCaseMessages.checkTitle,
  //   text: baseCaseMessages.checkText
  // }
}

const CasePlattenplaner = ({ styles }: Props) => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />} overflowed styles={styles.root}>
      <SEO
        title={formatMessage(messages.TitleCaseStudyPlattenpaner)}
        description={formatMessage(metaDescription.CaseStudyPlattenplanerDescription)}
        lang={locale}
      />
      <CaseStudy data={data} styles={styles.case} />
    </Layout>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(CasePlattenplaner)
